.wrapper {
  background-color: #fae596;
  padding: 55px 0 75px 0;
  overflow: hidden;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: "content image";
  margin: 0 150px;
  gap: 30px;
  height: 100%;
  align-items: center;
}

.title {
  font-size: 38px;
  margin-bottom: 15px;
}

.body {
  margin-bottom: 25px;
}

.content {
  grid-area: content;
  display: grid;
  margin: 0 8%;
}

.bold {
  font-weight: 700;
}

.img {
  grid-area: image;
  background: no-repeat center top url("../assets/Girl.jpg");
  width: 85%;
  height: 400px;
  background-size: cover;
  border-radius: 50px;
}

.img:after {
  content: "";
  display: inline-block;
  position: relative;
  border-radius: 50px;
  width: 100%;
  height: 100%;
  top: 20px;
  left: -20px;
  border: 4px dashed #3fb0ac;
}

@media (max-width: 1600px) {
  .img {
    width: 100%;
    height: 475px;
  }
}

@media (max-width: 1200px) {
  .grid {
    margin: 0 60px;
  }
}

@media (max-width: 1075px) {
  .title {
    font-size: 30px;
  }

  .body {
    font-size: 17px;
  }
}

@media (max-width: 950px) {
  .grid {
    grid-template-areas: "image" "content";
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
    margin: 0 20%;
  }

  .img {
    width: 100%;
    max-height: 310px;
    margin: auto;
  }
}

@media (max-width: 690px) {
  .grid {
    margin: 0 10%;
  }
}

@media (max-width: 515px) {
  .img {
    max-height: 240px;
  }

  .title {
    font-size: 25px;
  }

  .body {
    font-size: 16px;
  }

  .content {
    margin: 0;
  }
}

@media (max-width: 390px) {
  .img,
  .img:after {
    border-radius: 25px;
  }
}
