.wrapper {
  background: no-repeat center bottom url("../assets/Yellow_bg_1.png");
  min-height: 650px;
  background-size: cover;
}

.heroGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 0 10%;
  gap: 30px;
  height: 100%;
  grid-template-areas: "image content";
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.graphic {
  grid-area: image;
  align-self: center;
  display: grid;
  grid-template-rows: repeat(12, 1fr);
  grid-template-columns: repeat(12, 1fr);
  width: 100%;
  height: auto;
}

.content {
  grid-area: content;
  display: grid;
  place-content: center;
  margin: 0 6%;
}

.title {
  font-size: 45px;
  margin-bottom: 10px;
}

.body {
  font-size: 24px;
}

.bold {
  font-weight: 700;
}

.icon {
  font-size: 50px;
}

.foodTruck,
.menu,
.socialIconOne,
.socialIconTwo {
  box-shadow: #00000054 0px 8px 10px;
  border-radius: 100%;
}

.foodTruck {
  grid-column: 2 / 12;
  grid-row: 2 / 12;
  z-index: 5;
}

.menu {
  grid-column: 8 / 13;
  grid-row: 8 / 13;
  z-index: 10;
}

.socialIconOne,
.socialIconTwo {
  height: 70px;
  width: 70px;
  border-radius: 50px;
  background-color: #fff;
  place-self: center;
  z-index: 15;
  display: grid;
  place-items: center center;
}

.socialIconOne {
  grid-column: 1 / 3;
  grid-row: 6 / 8;
}

.socialIconTwo {
  grid-column: 2 / 4;
  grid-row: 9 / 11;
}

.dashOne,
.dashTwo,
.dashThree {
  width: 100%;
  height: auto;
  border-radius: 100%;
  border: 4.5px dashed #3fb0ac;
  animation: rotation 4s infinite linear;
}

.dashOne {
  grid-column: 10 / 13;
  grid-row: 6 / 9;
}

.dashTwo {
  grid-column: 7 / 10;
  grid-row: 10 / 13;
}

.dashThree {
  grid-column: 1 / 4;
  grid-row: 7 / 10;
}

@media (max-width: 1505px) {
  .title {
    font-size: 35px;
  }
}

@media (max-width: 1200px) {
  .heroGrid {
    margin: 0 5%;
    /* was 60px */
  }

  .wrapper {
    min-height: 550px;
  }
}

@media (max-width: 1075px) {
  .title {
    font-size: 30px;
  }

  .body {
    font-size: 17px;
  }
}

@media (max-width: 950px) {
  .heroGrid {
    grid-template-areas: "image image" "content content";
    margin: 0 20%;
  }
}

@media (max-width: 690px) {
  .heroGrid {
    grid-template-areas: "image image" "content content";
    margin: 0 10%;
  }
}

@media (max-width: 515px) {
  .wrapper {
    min-height: 475px;
  }

  .heroGrid {
    margin: 0 3%;
  }

  .graphic {
    grid-template-rows: repeat(12, 18px);
    grid-template-columns: repeat(12, 18px);
    justify-content: center;
  }

  .socialIconOne,
  .socialIconTwo {
    height: 35px;
    width: 35px;
  }

  .icon {
    font-size: 24px;
  }

  .title {
    font-size: 25px;
  }

  .body {
    font-size: 16px;
  }

  .dashOne,
  .dashTwo,
  .dashThree {
    border: 3px dashed #3fb0ac;
  }
}
