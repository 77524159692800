.wrapper {
  width: 260px;
  background-color: #dddfd471;
  border-radius: 30px;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  justify-items: center;
  text-align: center;
  padding: 25px 20px;
  margin: 0 10px 35px 15px;
  box-shadow: #0000001c 0px 8px 10px;
  transition: all 500ms ease;
}

.wrapper:hover {
  box-shadow: none;
  transition: all 500ms ease;
}

.img {
  width: 120px;
  margin-bottom: 15px;
}

@media (max-width: 1424px) {
  .wrapper {
    width: 210px;
    padding: 25px 20px;
    margin: 0 10px 35px 15px;
    /* border-radius: 30px; */
  }

  .img {
    width: 90px;
  }

  .title {
    font-size: 18px;
    margin-bottom: 8px;
  }

  .body {
    font-size: 16px;
  }
}

@media (min-width: 940px) and (max-width: 1174px) {
  .wrapper {
    flex-basis: 25%;
  }
}
